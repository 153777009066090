import React from "react";
import plugins from '../../content/pluginsInfo'
import news from '../images/news.jpg'
import newsto from '../images/newsto.jpg'
import Layout from "../components/layout"

export default class News extends React.Component{
    componentWillMount() {
        // window.scrollTo(0,0)
    }

    render() {
         const plugin=plugins[3]
        const styles=plugin.styleImgs.map((style,index)=><li className="animate-up" key={index}>
            <div > <img className="border"
                     src={style}
                     alt="示意图" /></div>

        </li>)
        const demos=plugin.useInDemos.map((demo,index)=>
          <li className="animate-up" key={index}>
              <a href={demo.path}> <img className="border"  src={demo.poster}  alt="示意图" />
                  <div>{demo.name}</div></a>
          </li>
        )
        return(
          <Layout>
            <div>
                <section className="section red cutoff">
                    <div className="pagewidth">
                        <h1>新闻公告栏</h1>
                        <h2>帮助您快速，轻松，更高效地管理网站上的新闻和公告。在指定的时间范围内自动化和安排公告，向访问者高亮显示最新资讯。</h2>
                    </div>
                </section>

                <section className="section light-gray cutoff">
                    <div className="pagewidth">
                        <h1>响应式</h1>
                        <img alt="响应式设计图" src={require("../images/responsive_news.jpg")}/>
                    </div>
                </section>

                <section className="section white cutoff">
                    <div className="pagewidth">
                        <h1>使用示例</h1>
                        <h2>鼠标悬停高亮，点击查看资讯详情</h2>
                        <ul className="grid2  animated">
                           <li> <img alt="赛事资讯" src={news}/></li>
                            <li> <img alt="赛事资讯" src={newsto}/></li>
                        </ul>

                    </div>
                </section>

                <section className="section light-gray cutoff">
                    <div className="pagewidth">
                        <h1>多种样式</h1>
                        <ul className="grid3 slide-up animated">
                            {styles}
                        </ul>
                    </div>
                </section>

                <section className="section light-gray cutoff">
                    <div className="pagewidth">
                        <h1>适用模板</h1>
                        <ul className="grid3 slide-up animated">
                            {demos}
                        </ul>
                    </div>
                </section>
            </div>
          </Layout>
        )
    }
}